<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="pl-5 pr-5">
    <v-card rounded="xl" elevation="0">
      <v-card-title>
        <v-tabs color="#031738">
          <v-tab
            class="ml-4"
            value="1"
            @click="selectedView = '1'"
          >
            Help Center Content
          </v-tab>
          <v-tab value="2" @click="selectedView = '2'">
            Create Content
          </v-tab>
          <v-spacer />
        </v-tabs>
      </v-card-title>
    </v-card>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="mt-5"
      >
        <v-card rounded="xl" elevation="0">
          <v-card-title>
            <v-select
              v-model="typeToDisplay"
              :items="typeToDisplayOptions"
              flat
              style="max-width: 150px;"
            />
          </v-card-title>
          <v-data-table
            v-if="helpContent.length"
            :loading="showProgress"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="helpContent"
            :footer-props="footerProps"
            :items-per-page="30"
            class="elevation-0"
          >
            <template v-slot:item.type="{ item }">
              <v-icon
                v-if="item.type === 'FAQ'"
                color="green lighten-1"
                class="pr-1"
                size="19"
              >
                mdi-frequently-asked-questions
              </v-icon>
              <v-icon
                v-else
                color="indigo lighten-1"
                class="pr-1"
                size="19"
              >
                mdi-help
              </v-icon>
              {{ item.type }}
            </template>
            <template v-slot:item.content="{ item }">
              {{ shorten(item.content) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              <v-icon
                color="indigo lighten-3"
                class="pr-1"
                size="19"
              >
                mdi-calendar
              </v-icon>{{ dayjs(item.createdAt).format('HH:mm dddd DD.MM.YYYY') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                dark
                fab
                x-small
                class="elevation-0"
                color="orange lighten-5"
                @click="editArticle(item)"
              >
                <v-icon dark color="orange">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                dark
                fab
                x-small
                class="ml-5 elevation-0"
                color="red lighten-5"
                @click="deleteDialogOpen(item)"
              >
                <v-icon dark color="red">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div v-if="!helpContent.length" class="text-center mt-5 pb-5 green--text">
            <v-icon color="green" size="50">
              mdi-check-circle
            </v-icon>
            <div class="mt-5">
              No Content
            </div>
            <v-icon color="green" class="mt-5">
              mdi-emoticon-happy-outline
            </v-icon>
          </div>
        </v-card>
        <v-dialog
          v-model="dialogDelete"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Delete help center article?
            </v-card-title>

            <v-card-text>
              <h2 class="red--text mt-4">
                {{ deleteItem.title }}
              </h2>
              <div class="mt-5" v-html="deleteItem.content" />
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                text
                @click="cancelDeleteArticle"
              >
                Cancel
              </v-btn>
              <v-spacer />
              <v-btn
                color="red"
                text
                @click="deleteArticle"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div
        v-else-if="selectedView === '2'"
        key="2"
        class="mt-5"
      >
        <v-card
          elevation="0"
          rounded="xl"
        >
          <v-card-title>
            Create Content
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Type of content
                </v-subheader>
              </v-col>
              <v-col>
                <v-select
                  v-model="typeToCreate"
                  :items="typeToDisplayOptions"
                  single-line
                  style="max-width: 400px;"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Category
                </v-subheader>
              </v-col>
              <v-col>
                <v-select
                  v-model="category"
                  :items="categoryToDisplayOptions"
                  single-line
                  style="max-width: 400px;"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Content title
                </v-subheader>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="contentTitle"
                  single-line
                  style="max-width: 400px;"
                  placeholder="Title"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Content
                </v-subheader>
              </v-col>
              <v-col>
                <ckeditor
                  v-model="contentToAdd"
                  :editor="editor"
                  :config="editorConfig"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  fab
                  class="mx-3 mb-5 elevation-0"
                  color="blue lighten-5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    size="30"
                    dark
                    color="blue"
                  >
                    mdi-backspace-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Clear form data</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  fab
                  class="mx-3 mb-5 elevation-0"
                  color="red lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelNew"
                >
                  <v-icon
                    size="30"
                    dark
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Cancel - throw away</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :dark="!typeToCreate || !contentTitle || !contentToAdd || !category ? false : true"
                  fab
                  class="mx-3 mb-5 elevation-0"
                  color="green lighten-5"
                  v-bind="attrs"
                  :disabled="!typeToCreate || !contentTitle || !contentToAdd || !category || showProgress"
                  v-on="on"
                  @click="createContent"
                >
                  <v-icon
                    v-if="!showProgress"
                    size="30"
                    dark
                    color="green"
                  >
                    mdi-content-save
                  </v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  />
                </v-btn>
              </template>
              <span>Save - create help center content</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </div>
      <div
        v-else-if="selectedView === '3'"
        key="3"
        class="mt-5"
      >
        <v-card
          elevation="0"
          rounded="xl"
        >
          <v-card-title>
            Edit Content
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Type of content
                </v-subheader>
              </v-col>
              <v-col>
                <v-select
                  v-model="editType"
                  :items="typeToDisplayOptions"
                  single-line
                  style="max-width: 400px;"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Category
                </v-subheader>
              </v-col>
              <v-col>
                <v-select
                  v-model="editCategory"
                  :items="categoryToDisplayOptions"
                  single-line
                  style="max-width: 400px;"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Content title
                </v-subheader>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editTitle"
                  single-line
                  style="max-width: 400px;"
                  placeholder="Title"
                  class="elevation-0"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-subheader>
                  Content
                </v-subheader>
              </v-col>
              <v-col>
                <ckeditor
                  v-model="editContent"
                  :editor="editor"
                  :config="editorConfig"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  fab
                  class="mx-3 mb-5 elevation-0"
                  color="red lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelEdit"
                >
                  <v-icon
                    size="30"
                    dark
                    color="red"
                  >
                    mdi-arrow-left-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Cancel - throw away</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  fab
                  class="mx-3 mb-5 elevation-0"
                  color="green lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="saveEdit"
                >
                  <v-icon
                    size="30"
                    dark
                    color="green"
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save - create help center content</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
// import Alignment from '@ckeditor/ckeditor5-editor-classic'
import dayjs from 'dayjs'

export default {
  name: 'Errors',
  components: {
  },
  data: () => ({
    dialogDelete: false,
    editor: ClassicEditor,
    editorData: '<p>Content of the editor.</p>',
    editorConfig: {
      heigh: 600
    },
    dayjs,
    typeToDisplay: 'FAQ',
    typeToDisplayOptions: ['FAQ', 'HELP'],
    categoryToDisplayOptions: ['Generell hjälp', 'Enheter och sensorer', 'Butik'],
    helpContent: [],
    selectedView: '1',
    showProgress: false,
    btnLoading: false,
    valid: false,
    pagination: {},
    errorId: '',
    errorIdRule: [
      v => !!v || 'Error ID is required'
    ],
    errorInfo: '',
    errorInfoRule: [
      v => !!v || 'Error Info is required'
    ],
    search: '',
    group: '',
    headers: [
      { text: '#', value: 'id' },
      { text: 'Type', value: 'type' },
      { text: 'Category', value: 'category' },
      { text: 'Title', value: 'title' },
      { text: 'Content', value: 'content' },
      { text: 'Date', value: 'createdAt' },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Views', value: 'counter' }
    ],
    footerProps: {
      'items-per-page-options': [20, 30, 50, 100]
    },
    typeToCreate: 'FAQ',
    contentTitle: '',
    contentToAdd: '',
    category: '',
    editId: '',
    editType: '',
    editTitle: '',
    editCategory: '',
    editContent: '',
    deleteItem: { content: '', id: 0, title: '' }
  }),
  computed: {

  },
  watch: {
    typeToDisplay: function (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.pullContent()
      }
    }
  },
  created () {
    this.pullContent()
  },
  methods: {
    deleteDialogOpen (item) {
      this.dialogDelete = true
      this.deleteItem = item
    },
    cancelDeleteArticle (item) {
      this.dialogDelete = false
      this.deleteItem = { content: '', id: 0, title: '' }
    },
    pullContent () {
      this.showProgress = true
      this.$store.dispatch('getHelpCenterContent', { type: this.typeToDisplay }).then(response => {
        this.helpContent = response.data
        this.showProgress = false
      })
    },
    editArticle (item) {
      this.selectedView = '3'
      this.editId = item.id
      this.editType = item.type
      this.editTitle = item.title
      this.editContent = item.content
      this.editCategory = item.category
    },
    cancelEdit () {
      this.selectedView = '1'
      this.editId = ''
      this.editType = ''
      this.editCategory = ''
      this.editTitle = ''
      this.editContent = ''
    },
    cancelNew () {
      this.selectedView = '1'
      this.typeToCreate = ''
      this.contentToAdd = ''
      this.contentTitle = ''
    },
    saveEdit () {
      this.showProgress = true
      this.$store.dispatch('editHelpCenterContent', { type: this.editType, content: this.editContent, title: this.editTitle, id: this.editId, category: this.editCategory })
        .then(() => {
          this.selectedView = '1'
          this.editId = ''
          this.editType = ''
          this.editCategory = ''
          this.editTitle = ''
          this.editContent = ''
          this.showProgress = false
          this.pullContent()
        })
    },
    createContent () {
      this.showProgress = true
      this.$store.dispatch('createHelpCenterContent', { type: this.typeToCreate, content: this.contentToAdd, title: this.contentTitle, category: this.category })
        .then(() => {
          this.selectedView = '1'
          this.typeToCreate = ''
          this.contentToAdd = ''
          this.contentTitle = ''
          this.category = ''
          this.showProgress = false
          this.pullContent()
        })
    },
    deleteArticle () {
      this.showProgress = true
      this.$store.dispatch('deleteHelpCenterContent', { id: this.deleteItem.id })
        .then(() => {
          this.showProgress = false
          this.dialogDelete = false
          this.pullContent()
        })
    },
    shorten (string) {
      const length = 60
      return string.length > length
        ? string.substring(0, length - 3) + '...'
        : string
    }
  }
}
</script>

<style>
    .ck-editor__editable {
        min-height: 500px;
    }
</style>
